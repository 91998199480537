import { Contract } from "@ethersproject/contracts";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};


export const getColor= (requestType)=>{
  if (requestType?.toLowerCase().includes("withdraw")) {
    return "red";
  } else if (
    requestType?.toLowerCase().includes("deposit") 
    ||
    requestType?.toLowerCase().includes("subscription purchase") ||
    requestType?.toLowerCase().includes("renewal")
  ) {
    return "#38c592";
  } else {
    return "gray";
  }
}


export const getType =(requestType)=>{
  if (requestType?.toLowerCase().includes("withdraw")) {
    return "Withdraw";
  } else if (
    requestType?.toLowerCase().includes("deposit") 
    ||
    requestType?.toLowerCase().includes("subscription purchase") ||
    requestType?.toLowerCase().includes("renewal")
  ) {
    return "Deposit";
  } else {
    return requestType || "--";
  }
}


// old One for phtsical one and physical 2 cards in subscription Management
// export const findCardType =(type)=>{
//   if(type.includes("virtual")){
//     return "Virtual"
//   }else if(type.includes("physical1")){
//     return "Physical"
//   }else{
//     return "Physical 1"
//   }
// }
export const findCardType =(type)=>{
  if(type.includes("Virtual")){
    return "Virtual"
  }
  else if(type.includes("Physical2")){
    return "Physical 2"
  }
  else if(type.includes("Physical") || type.includes("Physical1")){
    return "Physical 1"
  }
  
}

export const isSameUser = (currentMessage = {}, previousMessage = {}) => {
  const currentUserId = currentMessage?.user?._id;
  const previousUserId = previousMessage?.user?._id;
  return currentUserId === previousUserId;
};

export const isSameDay = (currentMessage = {}, previousMessage = {}) => {
  const currentDate = currentMessage?.createdAt;
  const previousDate = previousMessage?.createdAt;

  return (
      currentDate.getFullYear() === previousDate.getFullYear() &&
      currentDate.getMonth() === previousDate.getMonth() &&
      currentDate.getDate() === previousDate.getDate()
  );
};